<template>
  <div>
    <!-- <div class="row">
      <div class="col-xl-8">
        <Sessions></Sessions>
      </div>
      <div class="col-xl-4">
        <Free></Free>
      </div>
    </div> -->
    <Navigation></Navigation>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <inline-svg
              src="media/svg/icons/General/Attachment1.svg"
            ></inline-svg>
          </span>
          <h3 class="card-label">Integrações</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-lg-4">
            <!--begin::Navigation-->
            <ul
              class="navi navi-link-rounded navi-accent navi-hover flex-column mb-8 mb-lg-0"
              role="tablist"
            >
              <!--begin::Nav Item-->
              <template v-for="(integration, index) in integrations">
                <li class="navi-item mb-2" :key="index">
                  <button
                    class="navi-link"
                    :class="{ active: integration.name === selected.name }"
                    data-toggle="tab"
                    @click="select(integration)"
                  >
                    <span
                      class="navi-text font-size-h5 font-weight-bold"
                      :class="{
                        'navi-text text-dark-50 font-size-h5 font-weight-bold':
                          integration.name === selected.name,
                        'text-dark': integration.comp
                      }"
                      >{{ integration.name }}</span
                    >
                    <span
                      class="label label-inline mr-2"
                      v-if="integration.comming"
                      >Em Breve</span
                    >
                  </button>
                </li>
              </template>
            </ul>
            <!--end::Navigation-->
          </div>
          <div class="col-lg-8">
            <components :is="selected.comp" v-if="selected.comp"></components>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/view/layout/navigation";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "integrations",
  components: {
    Navigation
  },
  data() {
    return {
      selected: {
        name: "Wordpress",
        comp: () => import("./comps/wordpress.vue")
      },
      integrations: [
        {
          id: "#wordpress",
          name: "Wordpress",
          comp: () => import("./comps/wordpress.vue")
        },
        {
          id: "#gtm",
          name: "Google Tag Manager (GTM)",
          comp: () => import("./comps/gtm")
        },
        {
          id: "#tray",
          name: "Tray Commerce",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#lojaintegrada",
          name: "Loja Integrada",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#magento",
          name: "Magento",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#shopify",
          name: "Shopify",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#prestashop",
          name: "PrestaShop",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#vtex",
          name: "VTEX",
          comp: () => import("./comps/comingSoon"),
          comming: true
        },
        {
          id: "#nuvemshop",
          name: "Nuvem Shop",
          comp: () => import("./comps/comingSoon"),
          comming: true
        }
      ]
    };
  },
  methods: {
    setHash() {
      if (this.$route.hash) {
        let index = this.list.indexOf(this.$route.hash);
        this.select(this.integrations[index]);
      }
    },
    select(integration) {
      this.selected = integration;
      if (this.$route.hash !== integration.id) {
        this.$router.replace(integration.id);
      }
    }
  },
  computed: {
    list() {
      return this.integrations.map(item => {
        return item.id;
      });
    }
  },
  mounted() {
    this.setHash();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Integrations" }]);
  }
};
</script>
